import React, { useState } from "react";
import PrivacyPolicyENUS from "./PrivacyPolicyENUS";
import PrivacyPolicyPTBR from "./PrivacyPolicyPTBR";
import { Button, Container, Row, Image } from "react-bootstrap";
import MainNavBar from "./MainNavBar";

function PrivacyPolicy() {
    const [curLang, setCurlang] = useState("ptbr");

    return (
        <div>
            <MainNavBar />
            <Container className="text-start">
                <div className="d-flex justify-content-center">
                    <button
                        className="m-3 border border-0 bg-white"
                        onClick={() => {
                            setCurlang("ptbr");
                        }}
                    >
                        <img src="/assets/br.svg" width={"50px"} />
                    </button>
                    <button
                        className="m-3 border border-0 bg-white"
                        onClick={() => {
                            setCurlang("enus");
                        }}
                    >
                        <img src="/assets/us.svg" width={"50px"} />
                    </button>
                </div>
                {curLang == "enus" && <PrivacyPolicyENUS />}
                {curLang == "ptbr" && <PrivacyPolicyPTBR />}
            </Container>
        </div>
    );
}

export default PrivacyPolicy;
