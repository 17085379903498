import React from "react";

function PrivacyPolicyPTBR() {
    return (
        <div>
            <strong>Política de Privacidade</strong>
            <p>
                Esta política de privacidade se aplica ao aplicativo VerCarro
                (doravante referido como "Aplicativo") para dispositivos móveis,
                criado pela Devenger (doravante referido como "Prestador de
                Serviço") como um serviço gratuito. Este serviço é fornecido
                "COMO ESTÁ".
            </p>
            <br />
            <strong>Coleta e Uso de Informações</strong>
            <p>
                O Aplicativo coleta informações quando você o baixa e usa. Essas
                informações podem incluir:
            </p>
            <ul>
                <li>
                    O endereço IP do seu dispositivo (por exemplo, endereço IP)
                </li>
                <li>
                    As páginas do Aplicativo que você visita, a data e horário
                    da visita, o tempo gasto nessas páginas
                </li>
                <li>O tempo gasto no Aplicativo</li>
                <li>O sistema operacional do seu dispositivo móvel</li>
            </ul>
            <p></p>
            <br />
            <p className="d-none">
                O Aplicativo não coleta informações precisas sobre a localização
                do seu dispositivo móvel.
            </p>
            <div>
                <p>
                    O Aplicativo coleta a localização do seu dispositivo,
                    ajudando o Prestador de Serviço a determinar sua localização
                    geográfica aproximada e utilizá-la das seguintes maneiras:
                </p>
                <ul>
                    <li>
                        Serviços de Geolocalização: O Prestador de Serviço
                        utiliza dados de localização para fornecer recursos como
                        conteúdo personalizado, recomendações relevantes e
                        serviços baseados na localização.
                    </li>
                    <li>
                        Análises e Melhorias: Dados de localização agregados e
                        anonimizados ajudam o Prestador de Serviço a analisar o
                        comportamento dos usuários, identificar tendências e
                        melhorar o desempenho e funcionalidade do Aplicativo.
                    </li>
                    <li>
                        Serviços de Terceiros: Ocasionalmente, o Prestador de
                        Serviço pode transmitir dados de localização
                        anonimizados para serviços externos, auxiliando na
                        otimização e aprimoramento do Aplicativo.
                    </li>
                </ul>
            </div>
            <br />
            <p>
                O Prestador de Serviço pode usar as informações fornecidas para
                entrar em contato com você ocasionalmente e fornecer informações
                importantes, avisos necessários e promoções de marketing.
            </p>
            <br />
            <p>
                Para uma melhor experiência, o Prestador de Serviço pode
                solicitar que você forneça determinadas informações pessoais,
                incluindo, mas não se limitando a, Email, ID de Usuário e
                Telefone. Essas informações serão mantidas pelo Prestador de
                Serviço conforme descrito nesta política de privacidade.
            </p>
            <br />
            <strong>Acesso de Terceiros</strong>
            <p>
                Apenas dados agregados e anonimizados são periodicamente
                transmitidos para serviços externos para ajudar o Prestador de
                Serviço a melhorar o Aplicativo e seu serviço. O Prestador de
                Serviço pode compartilhar suas informações com terceiros
                conforme descrito nesta política.
            </p>
            <div>
                <br />
                <p>
                    Observe que o Aplicativo utiliza serviços de terceiros que
                    possuem suas próprias Políticas de Privacidade sobre o
                    tratamento de dados. Abaixo estão os links para as Políticas
                    de Privacidade dos provedores de serviços de terceiros
                    utilizados pelo Aplicativo:
                </p>
                <ul>
                    <li>
                        <a
                            href="https://www.google.com/policies/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Google Play Services
                        </a>
                    </li>
                </ul>
            </div>
            <br />
            <p>
                O Prestador de Serviço pode divulgar informações fornecidas pelo
                usuário e informações coletadas automaticamente:
            </p>
            <ul>
                <li>
                    Conforme exigido por lei, como para cumprir uma intimação ou
                    processo legal semelhante;
                </li>
                <li>
                    Quando acredita de boa-fé que a divulgação é necessária para
                    proteger seus direitos, sua segurança ou a segurança de
                    terceiros, investigar fraudes ou responder a solicitações
                    governamentais;
                </li>
                <li>
                    Com prestadores de serviços confiáveis que trabalham em seu
                    nome, não têm uso independente das informações divulgadas a
                    eles e concordam em seguir as regras estabelecidas nesta
                    política.
                </li>
            </ul>
            <p></p>
            <br />
            <strong>Direito de Exclusão</strong>
            <p>
                Você pode interromper toda a coleta de informações pelo
                Aplicativo facilmente desinstalando-o. Você pode usar os
                processos padrão de desinstalação disponíveis em seu dispositivo
                móvel ou através da loja de aplicativos.
            </p>
            <br />
            <strong>Política de Retenção de Dados</strong>
            <p>
                O Prestador de Serviço reterá os dados fornecidos pelo usuário
                enquanto você utilizar o Aplicativo e por um período razoável
                posteriormente. Se desejar que os dados fornecidos sejam
                excluídos, entre em contato pelo e-mail
                webmaster@devenger.com.br, e responderemos dentro de um prazo
                razoável.
            </p>
            <br />
            <strong>Crianças</strong>
            <p>
                O Prestador de Serviço não utiliza o Aplicativo para solicitar
                intencionalmente dados ou realizar marketing para crianças
                menores de 13 anos.
            </p>
            <div>
                <br />
                <p>
                    O Aplicativo não se destina a menores de 13 anos. O
                    Prestador de Serviço não coleta intencionalmente informações
                    pessoais identificáveis de crianças menores de 13 anos. Caso
                    descubra que uma criança forneceu informações pessoais, o
                    Prestador de Serviço excluirá imediatamente esses dados de
                    seus servidores. Se você é pai, mãe ou responsável e está
                    ciente de que seu filho forneceu informações pessoais, entre
                    em contato pelo e-mail webmaster@devenger.com.br para que as
                    medidas adequadas sejam tomadas.
                </p>
            </div>
            <br />
            <strong>Segurança</strong>
            <p>
                O Prestador de Serviço se preocupa com a proteção da
                confidencialidade de suas informações. São implementadas
                salvaguardas físicas, eletrônicas e procedimentais para proteger
                os dados processados e armazenados.
            </p>
            <br />
            <strong>Alterações</strong>
            <p>
                Esta Política de Privacidade pode ser atualizada periodicamente
                por qualquer motivo. O Prestador de Serviço notificará sobre
                quaisquer alterações atualizando esta página com a nova Política
                de Privacidade. Recomendamos que consulte esta Política de
                Privacidade regularmente, pois o uso contínuo do Aplicativo será
                considerado como aceitação das alterações.
            </p>
            <br />
            <p>Esta política de privacidade é válida a partir de 12-02-2025</p>
            <br />
            <strong>Seu Consentimento</strong>
            <p>
                Ao usar o Aplicativo, você concorda com o processamento de suas
                informações conforme estabelecido nesta Política de Privacidade,
                incluindo suas futuras alterações.
            </p>
            <br />
            <strong>Contato</strong>
            <p>
                Se você tiver dúvidas sobre privacidade ao usar o Aplicativo,
                entre em contato com o Prestador de Serviço pelo e-mail
                webmaster@devenger.com.br.
            </p>
            <hr />
            <p>
                Esta página de política de privacidade foi gerada por{" "}
                <a
                    href="https://app-privacy-policy-generator.nisrulz.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    App Privacy Policy Generator
                </a>
            </p>
        </div>
    );
}

export default PrivacyPolicyPTBR;
